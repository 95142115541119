@import url(https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;500;600;700&display=swap);
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: 'Poppins', sans-serif;
}
.wrapper {
  width: 100%;
  min-height: 100vh;
}
.toggleOnApp {
  margin-bottom: 2em;
  display: flex;
  justify-content: flex-end;
  align-items: center;
}
.toggleOnApp > p {
  margin-right: 2em;
}
.toggleOnApp > button {
  border: 0;
  outline: 0;
  padding: .5em 1.5em;
  font-weight: 500;
  background: black;
  color: white;
  cursor: pointer;
}
#appOnBtn {
  color: teal;
}
#appOffBtn {
  color: red;
}
#productOrderCount {
  font-weight: 500;
}

.newOrderNotification {
  width: 400px;
  background: white;
  position: fixed;
  top: 65px;
  left: calc(50% - 200px);
  z-index: 999;
  padding: 10px 10px 25px 10px;
  box-shadow: 0 0px 10px rgb(0 0 0 / 40%);
}
.newOrderNotification--header {
  display: flex;
  justify-content: flex-end;
}
.newOrderNotification--header > svg {
  font-size: 30px;
  cursor: pointer;
}
.newOrderNotification--content > h3 {
  text-align: center;
  margin-bottom: .5em;
  margin-top: .5em;
}
.newOrderNotification--content > a {
border: 0;
outline: 0;
margin-left: auto;
margin-right: auto;
width: -webkit-fit-content;
width: -moz-fit-content;
width: fit-content;
display: block;
padding: .75em 1.5em;
font-weight: 500;
background: teal;
color: white;
border-radius: 10px;
text-decoration: none;
font-size: .8rem;
}
.login > form {
    width: 90%;
    margin-left: auto;
    margin-right: auto;
    padding: 1em;
    display: flex;
    flex-wrap: wrap;
    flex-direction: column;
}
.login {
    width: 100%;
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
}
.login > form > h2 {
    margin-bottom: 1em;
}
.login > form > input {
    margin-bottom: 1em;
    width: 100%;
    height: 45px;
    outline: 0;
    border: 2px solid lightgray;
    padding: 1em;
    font-weight: 500;
    transition: all .3s ease-in-out;
}
.login > form > input:focus {
    border: 2px solid rgb(17, 17, 17);
}
.login > form > button {
    margin-top: 1em;
    height: 45px;
    border: 0;
    outline: 0;
    background: rgb(17, 17, 17);
    color: white;
    font-weight: 500;
    font-size: 1.1rem;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
}
.login > form > label {
    display: block;
    margin-bottom: .2em;
    font-weight: 500;
    font-size: 1.2rem;
}
.login > form > p {
    margin-top: 2em;
    color: red;
    font-size: .8rem;
    text-align: center;
}
.dashboard {
    width: 100%;
    background: white;
    min-height: 100vh;
}
.dashboard__menu {
    width: 100%;
    height: 60px;
    background: white;
    box-shadow: 0 0px 10px rgb(0 0 0 / 20%);
    display: flex;
}
.dashboard__menu > a {
    display: flex;
    height: 60px;
    flex-grow: 1;
    padding: 0 1em;
    justify-content: center;
    align-items: center;
    text-decoration: none;
    font-weight: 500;
    color: black;
    border-right: 1px solid lightgray;
}
.dashboard__menu > a:last-child {
    border-right: 0;
}
.dashboard__orders {
    width: 100%;
    min-height: calc(100vh - 60px);
    padding: 2em 2em;
}
#activeLink {
    background: lightgray;
    transition: all .3s ease-in-out;
    color: teal;
}

.displayOrders > h2 {
    margin-bottom: 20px;
}

#welcomeTheme {
    display: flex;
    justify-content: center;
    align-items: center;
    height: calc(100vh - 4em - 60px);
}

.orderItem {
    width: 100%;
    padding: 1em 2em;
    margin-bottom: 2em;
    box-shadow: 0 0px 10px rgb(0 0 0 / 20%);
    position: relative;
}
.orderItem:last-child {
    margin-bottom: 0;
}
.orderItem--product {
    margin-bottom: .5em;
}
.orderItem--product:last-child {
    margin-bottom: 0;
}
.orderItem--product > ul {
    list-style-position: inside;
}
.orderItem--product > ul > li {
    font-size: .9rem;
}
.orderItem--number {
    font-weight: 500;
    font-size: 1.2rem;
    margin-bottom: .25rem;
}
#orderDate {
    font-size: 0.9rem;
}
.orderItem > label {
    font-weight: 500;
}
.orderItem > span {
    display: block;
    font-size: .9rem;
}
#rejectOrderBtn {
    padding: .5em 1em;
    border: 0;
    outline: 0;
    background-color: red;
    color: white;
    font-weight: 500;
    margin-top: 1em;
    cursor: pointer;
}
.changeDeliveryTime > span {
    font-size: .9rem;
    display: block;
    margin-bottom: .2em;
    margin-top: .5em;
}
.changeDeliveryTime > label {
    display: flex;
}
.changeDeliveryTime > label > button {
    border: 0;
    outline: 0;
    background-color: teal;
    padding: .5em 1em;
    font-weight: 500;
    color: white;
    cursor: pointer;
}
#timeChange {
    width: 300px;
    margin-right: 2em;
}

.accepteReject {
    width: 100%;
    height: 100vh;
    background: rgba(0,0,0, .5);
    position: fixed;
    top: 0;
    left: 0;
    z-index: 999;
    display: flex;
    justify-content: center;
    align-items: center;
}

.rejectBox {
    width: 80%;
    padding: 2em 1em;
    background: white;
    box-shadow: 0 0px 10px rgb(0 0 0 / 40%);
    border-radius: 10px;
}
.rejectBox > h2 {
    text-align: center;
    margin-bottom: 1em;
}
.rejectBox > label {
    width: 100%;
    display: flex;
    justify-content: center;
}
.rejectBox > label > button:nth-child(1) {
margin-right: 3em;
background: rgb(136, 136, 136);
}
.rejectBox > label > button {
padding: .75em 2.5em;
border: 0;
outline: 0;
border-radius: 10px;
background: red;
font-weight: 500;
color: white;
box-shadow: 0 0px 5px rgb(0 0 0 / 20%)
}



#toDeliveryBtn {
    padding: .75em 2.5em;
    margin-top: 1em;
    border: 0;
    outline: 0;
    background: teal;
    color: white;
    font-weight: 500;
    box-shadow: 0 0px 5px rgb(0 0 0 / 20%);
    border-radius: 10px;
}

#finishedOrd {
    color: teal;
}
#rejectedOrd {
    color: red;
}

#printTicket {
    position: absolute;
    top: 1em;
    right: 1em;
    background-color: #3b5998;
    padding: 0.75em 1.5em;
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: 500;
    color: white;
    border: 0;
    outline: 0;
    border-radius: 10px;
}
#printTicket > svg {
    margin-left: .5em;
}
.refreshBtn {
    padding: .75em 2.5em;
    background-color: #3b5998;
    color: white;
    display: flex;
    justify-content: center;
    align-items: center;
    font-weight: 500;
    margin-bottom: 1em;
    border-radius: 10px;
    box-shadow: 0 0px 10px rgb(0 0 0 / 40%);
    font-size: 1rem;
    cursor: pointer;
}
.refreshBtn > svg {
    margin-left: .5em;
}
.notfound {
    width: 100%;
    height: calc(100vh - 4em - 115px);
    display: flex;
    justify-content: center;
    align-items: center;
}

#printHeader {
    color: red;
}
