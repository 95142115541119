.wrapper {
  width: 100%;
  min-height: 100vh;
}
.toggleOnApp {
  margin-bottom: 2em;
  display: flex;
  justify-content: flex-end;
  align-items: center;
}
.toggleOnApp > p {
  margin-right: 2em;
}
.toggleOnApp > button {
  border: 0;
  outline: 0;
  padding: .5em 1.5em;
  font-weight: 500;
  background: black;
  color: white;
  cursor: pointer;
}
#appOnBtn {
  color: teal;
}
#appOffBtn {
  color: red;
}
#productOrderCount {
  font-weight: 500;
}

.newOrderNotification {
  width: 400px;
  background: white;
  position: fixed;
  top: 65px;
  left: calc(50% - 200px);
  z-index: 999;
  padding: 10px 10px 25px 10px;
  box-shadow: 0 0px 10px rgb(0 0 0 / 40%);
}
.newOrderNotification--header {
  display: flex;
  justify-content: flex-end;
}
.newOrderNotification--header > svg {
  font-size: 30px;
  cursor: pointer;
}
.newOrderNotification--content > h3 {
  text-align: center;
  margin-bottom: .5em;
  margin-top: .5em;
}
.newOrderNotification--content > a {
border: 0;
outline: 0;
margin-left: auto;
margin-right: auto;
width: fit-content;
display: block;
padding: .75em 1.5em;
font-weight: 500;
background: teal;
color: white;
border-radius: 10px;
text-decoration: none;
font-size: .8rem;
}