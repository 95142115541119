.login > form {
    width: 90%;
    margin-left: auto;
    margin-right: auto;
    padding: 1em;
    display: flex;
    flex-wrap: wrap;
    flex-direction: column;
}
.login {
    width: 100%;
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
}
.login > form > h2 {
    margin-bottom: 1em;
}
.login > form > input {
    margin-bottom: 1em;
    width: 100%;
    height: 45px;
    outline: 0;
    border: 2px solid lightgray;
    padding: 1em;
    font-weight: 500;
    transition: all .3s ease-in-out;
}
.login > form > input:focus {
    border: 2px solid rgb(17, 17, 17);
}
.login > form > button {
    margin-top: 1em;
    height: 45px;
    border: 0;
    outline: 0;
    background: rgb(17, 17, 17);
    color: white;
    font-weight: 500;
    font-size: 1.1rem;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
}
.login > form > label {
    display: block;
    margin-bottom: .2em;
    font-weight: 500;
    font-size: 1.2rem;
}
.login > form > p {
    margin-top: 2em;
    color: red;
    font-size: .8rem;
    text-align: center;
}