.notfound {
    width: 100%;
    height: calc(100vh - 4em - 115px);
    display: flex;
    justify-content: center;
    align-items: center;
}

#printHeader {
    color: red;
}